import emailjs from "@emailjs/browser";
import secrets from "./secrets.json";

import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";

// email JS init
emailjs.init(String(secrets["EMAIL_JS_USER_ID"]));

const stage = process.env.NODE_ENV === "development" ? "dev" : "prod";
// const stage = "prod";
const identityPoolId =
  stage === "prod"
    ? "us-east-1:75fa1cb8-3ae1-47e5-a38f-34acaefe33ed"
    : "us-east-1:7f0af29e-12f3-400e-bb16-a92cadf6e0b8";

// References:
// https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#manual-setup-import-storage-bucket
// https://docs.aws.amazon.com/AmazonS3/latest/API/browser-based-uploads-aws-amplify.html
const storageConfig = {
  Auth: {
    identityPoolId,
    region: awsExports.aws_cognito_region,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: `anonymous-agent-web-s3`,
      region: awsExports.aws_project_region,
    },
  },
};

const AMPLIFY_CONFIG = {
  ...awsExports,
  ...storageConfig,
};

// Amplify init
Amplify.configure(AMPLIFY_CONFIG);

// Have to explicitly invoke this, otherwise getting
// "No Auth module registered in Amplify" error.
const UNUSED = Auth;
