import DefaultHeaderFooter from "../../DefaultHeaderFooter";
import { Box, Container, Typography } from "@mui/material";

export default function AppTester() {
  return (
    <DefaultHeaderFooter>
      <Container
        sx={{
          padding: "50px 40px 30px 40px",
          margin: "auto",
          maxWidth: { md: "840px" },
        }}
      >
        <Typography variant="h1" sx={{ my: "40px", textAlign: "center" }}>
          App Tester & Promoter
        </Typography>

        <Typography>
          We are a tech startup focusing on effective social media marketing. We
          are looking for App Testers and Promoters in New York City. This is a
          remote role, but we do request authentic experience: the tester needs
          to complete one of the contracts exhibited in the app in person. You
          will be compensated for completing the contract in the app and also
          optionally promoting our app by sharing your experience on social
          media. Total compensation should be $80 on average for 20 extra
          minutes of your time.
        </Typography>

        <Box sx={{ margin: "30px" }}></Box>

        {/* Responsibilities */}
        <Typography variant="h6">Responsibilities</Typography>

        <ul>
          <li>
            <Typography>
              Register an account and select a contract of your choice
            </Typography>
          </li>

          <li>
            <Typography>
              Finish the contract as guided: try the service, upload your
              receipt, and upload social media post screenshot (based on
              contract)
            </Typography>
          </li>

          <li>
            <Typography>
              Share your experience to promote the app. Additional bonus applies
              based on performance
            </Typography>
          </li>
        </ul>

        {/* Requirements */}
        <Typography variant="h6">Requirements</Typography>

        <ul>
          <li>
            <Typography>You are in NYC</Typography>
          </li>

          <li>
            <Typography>
              You enjoy your life and periodically visit local restaurants,
              salons or spas etc.
            </Typography>
          </li>

          <li>
            <Typography>
              You need to use one of our supported social media platforms:
              Instagram ,TikTok, Facebook, 小红书 / Little Red Book
            </Typography>
          </li>
        </ul>

        {/* Perks */}
        <Typography variant="h6">Perks</Typography>

        <ul>
          <li>
            <Typography>No resume needed! YES!</Typography>
          </li>

          <li>
            <Typography>No experience needed! YES!</Typography>
          </li>
          <li>
            <Typography>Flexible schedule</Typography>
          </li>
          <li>
            <Typography>
              Additional compensation if you complete more than one contract!
            </Typography>
          </li>
        </ul>

        <Box sx={{ margin: "40px" }}></Box>

        <Typography>
          <b>
            To Apply: please fill out your information on this form:
            <a href="https://tinyurl.com/ymhkbnak">
              https://tinyurl.com/ymhkbnak
            </a>
          </b>
        </Typography>
      </Container>
    </DefaultHeaderFooter>
  );
}
