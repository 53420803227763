import DefaultHeaderFooter from "../../DefaultHeaderFooter";
import { Box, Container, Link, Typography } from "@mui/material";

export default function UIDesigner() {
  return (
    <DefaultHeaderFooter>
      <Container
        sx={{
          padding: "50px 40px 30px 40px",
          margin: "auto",
          maxWidth: { md: "840px" },
        }}
      >
        <Typography variant="h1" sx={{ my: "40px", textAlign: "center" }}>
          UI Designer
        </Typography>

        <Typography>
          We are a tech startup focusing on effective social media marketing. We
          are looking for a part-time UI Designer starting ASAP. This is a
          remote role. The salary is $20-$40/hour depending on your experience
          and design.
        </Typography>

        <Box sx={{ margin: "30px" }}></Box>

        {/* Responsibilities */}
        <Typography variant="h6">Responsibilities</Typography>

        <ul>
          <li>
            <Typography>
              Create original design and theme to match product ideology
            </Typography>
          </li>

          <li>
            <Typography>
              Design the aesthetics to be implemented within a website or
              product, from the layout menus and drop-down options to colors and
              fonts
            </Typography>
          </li>

          <li>
            <Typography>
              Execute all visual design stages from concept to final hand-off to
              engineering
            </Typography>
          </li>
          <li>
            <Typography>
              Collaborate with the product team and software engineers to define
              and implement modern and clean user interface
            </Typography>
          </li>
          <li>
            <Typography>
              Improve current wireframes and UX for a more streamlined user
              experience
            </Typography>
          </li>
        </ul>

        {/* Requirements */}
        <Typography variant="h6">Requirements</Typography>

        <ul>
          <li>
            <Typography>
              Proven UI experience and demonstrable UI design skills with a
              strong portfolio
            </Typography>
          </li>

          <li>
            <Typography>
              Proficiency in Figma, Sketch, InVision or other visual design and
              prototyping tools
            </Typography>
          </li>

          <li>
            <Typography>
              Excellent visual design skills with sensitivity to user-system
              interaction{" "}
            </Typography>
          </li>
          <li>
            <Typography>
              Up-to-date with the latest UI trends, techniques, and technologies
            </Typography>
          </li>
          <li>
            <Typography>
              Proficiency in HTML, CSS, and JavaScript is a plus
            </Typography>
          </li>
          <li>
            <Typography>Familiarity with React/MUI is a plus</Typography>
          </li>
        </ul>

        {/* Perks */}
        <Typography variant="h6">Perks</Typography>

        <ul>
          <li>
            <Typography>Flexibile schedule</Typography>
          </li>

          <li>
            <Typography>Potential full-time extension</Typography>
          </li>

          <li>
            <Typography>Competitive salary ($20-$40/hour)</Typography>
          </li>
        </ul>

        <Box sx={{ margin: "40px" }}></Box>

        <Typography>
          <b>
            To Apply: please email your resume to{" "}
            <Link href="mailto:careers@aagent.co">careers@aagent.co</Link> and
            indicate the role(s) that you are interested in.
          </b>
        </Typography>
      </Container>
    </DefaultHeaderFooter>
  );
}
