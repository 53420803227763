import { Box, Container, Typography } from "@mui/material";
import { ReactNode } from "react";

function FAQ({
  title,
  content,
  contentElement,
}: {
  title: ReactNode;
  content?: string;
  contentElement?: ReactNode;
}) {
  return (
    <Box my="28px" sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "800px" }}>
        <Typography variant="h2" sx={{ fontWeight: "normal" }}>
          {title}
        </Typography>
        {!!content && (
          <Typography variant="body1" sx={{ color: "#777", mt: "4px" }}>
            {content}
          </Typography>
        )}
        {!!contentElement && contentElement}
      </Box>
    </Box>
  );
}

export default function FAQSection() {
  return (
    <Container id="faq" sx={{ pt: { xs: "30px", md: "80px" } }}>
      <Box sx={{ textAlign: "center", mb: "40px" }}>
        <Typography variant="h1">FAQ</Typography>
      </Box>

      <FAQ
        title="Who to contact with additional questions?"
        content="https://www.aagent.co/contact"
      />
      <FAQ
        title="Can I use an old receipt or post?"
        content="No. Timestamps on your receipt and post need to be after the timestamp you pick a deal."
      />
      <FAQ
        title="Can a receipt or post be used more than once?"
        content="No. If you used a receipt for a deal, others will not be able to use the same receipt for a deal. Same for a post."
      />
      <FAQ
        title="How does payment work?"
        content="Gift cards, Venmo or payments to your bank account. Bank transfers are handled by our third party partner Stripe."
      />
      <FAQ
        title="Do we need to pay when visiting the vendors?"
        content="Yes, it's hard to strike a deal with merchants to offer free service/meal + bonus for just a minimum requirement of 20 likes. However, we do try so that the compensation can cover your cost. For an average party of 4, your reimbursement + bonus should be able to cover your personal spend with extra."
      />
      <FAQ
        title="When I pick a deal and try the vendor out, do I have to post about it?"
        content="We are a social media marketing platform that connects influencers and merchants. You need to post about it. However, you get to decide the content and style. Basically, as long as you mention the merchant. Even if your post does not meet the requirements, you will still get partially rewarded for some deals."
      />
      <FAQ
        title="Reach a certain amount of likes for me to get paid?"
        content="There are requirements for the amount of likes. It should be very easy. We want to help people with from 20 likes to 100+ likes. We do have a VIP department. It's currently developing needs for merchants so the pay is higher for influencers. We also strive to share profit with influencers who exceed the requirements. It should come later this year."
      />
      <FAQ
        title="What exactly do I have to do?"
        contentElement={
          <Typography variant="body1" sx={{ color: "#777", mt: "4px" }}>
            As mentioned, we created a platform for influencers to share and
            earn. In order to share authentic experiences and earn rewards, most
            contracts follow the following steps:
            <br />
            <span style={{ marginRight: "20px" }} />
            1. Pick a deal and accept it.
            <br />
            <span style={{ marginRight: "20px" }} />
            2. Experience the service (restaurants, salons, spas etc.), collect
            and upload the receipt.
            <br />
            <span style={{ marginRight: "20px" }} />
            3. Post on an accepted social media of your choice.
            <br />
            <span style={{ marginRight: "20px" }} />
            4. Upload relevant information (social media screenshots, # of
            likes, etc.) and submit.
            <br />
            <span style={{ marginRight: "20px" }} />
            5. Collect payments after approval (usually around 2 days)
          </Typography>
        }
      />
      <FAQ
        title="Will the other deals/contracts remain available after I complete one?"
        content="Yes, we pay you additional bonus for finishing your first one, but the others will remain available. Feel free to make more money by completing more."
      />
      <FAQ
        title="Can we do it more than once?"
        content="For most vendors, you should not see the same contract/deal more than once every 6 weeks."
      />
      <FAQ
        title="What does 51%+ of the contents about the vendor mean?"
        content="It means your post should be mainly about this vendor. The first photo and more than half of the photos created in a post should be relevant to the vendor and your experiences with the vendor. For example, if your post contains 3 photos, then at least 2 photos should be relevant."
      />
      <FAQ
        title="How many days can each post have after posting to rack up all the likes?"
        content="Currently we allow 10 days to complete a deal, starting from 'Confirm and Begin' the deal, ending at hitting the 'Submit for Approval' button.
        Users can freely allocate the time in the intermediate steps. For example, if your post is made 3 days after the deal is accepted, you will have 7 days remaining to upload the screenshots and submit for approval.
        "
      />
      <FAQ
        title="Can we change the tiers after we pick a deal?"
        content="Currently our app requires an influencer to select a tier before accepting the deal, due to the vendors' budget constraint. We will continue to observe the user behaviors and will try dynamic tier selection in the future."
      />
      <FAQ
        title="Is the first deal bonus a bonus for the first time you visit each vendor or the first time completing a deal on the app?"
        content="It's a bonus for completing your first deal in the app."
      />
      <FAQ
        title="How long will the approval take after submission?"
        content="If no further clarification is needed, an approval will be granted within 24 hours. We guarantee the turn-around time to be less than 72 hours."
      />
    </Container>
  );
}
