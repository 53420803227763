import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

type StepProps = {
  icon?: any;
  children: any;
  dotSx?: any;
  sx?: any;
};

export function Step({ icon, children, dotSx, sx }: StepProps) {
  let itemSx = { ":before": { flex: { xs: 0 } } };
  if (!!sx) itemSx = { ...itemSx, ...sx };

  return (
    <TimelineItem sx={itemSx}>
      <TimelineSeparator
        sx={{ "& .MuiTimelineConnector-root": { backgroundColor: "grey.100" } }}
      >
        <TimelineConnector />
        <TimelineDot sx={{ backgroundColor: "grey.300", ...dotSx }}>
          {icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{ p: "12px 24px 12px 16px", m: "auto", overflow: "hidden" }}
      >
        {children}
      </TimelineContent>
    </TimelineItem>
  );
}

type StepsProps = {
  children?: ReactNode;
};

export default function Steps({ children }: StepsProps) {
  return <Timeline sx={{ p: 0 }}>{children}</Timeline>;
}

export function ToggleStepUncontrolled(props: any) {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <ToggleStep {...props} expanded={expanded} setExpanded={setExpanded} />
  );
}

export function ToggleStep({
  title,
  expanded,
  children,
  setExpanded,
  ...rest
}: any) {
  const ExpandIcon = expanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Step {...rest}>
      <Box>
        <Box
          sx={{
            backgroundColor: "rgba(238, 234, 248, 0.75)",
            height: "80px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            pl: { xs: "20px", md: "40px" },
            pr: { xs: "20px", md: "32px" },
          }}
          onClick={() => !!setExpanded && setExpanded(!expanded)}
        >
          <Typography sx={{ fontSize: "18px" }}>{title}</Typography>
          <ExpandIcon sx={{ ml: "10px" }} />
        </Box>

        {expanded && children}
      </Box>
    </Step>
  );
}
