import DefaultHeaderFooter from "./DefaultHeaderFooter";
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as React from "react";
import EmailWaitList from "./EmailWaitList";
import MerchantHeaderImg from "./assets/merchant_header.png";
import GreenCheckMark from "./assets/green_check_mark.png";
import GreyCheckMark from "./assets/grey_check_mark.png";
import OperationIconPng from "./assets/operation_icon.png";
import OperationIcon2Png from "./assets/operation_icon_2.png";
import OperationIcon4Png from "./assets/operation_icon_4.png";
import HealthPng from "./assets/health.png";
import HandShakePng from "./assets/handShake.png";
import SocialMediaPng from "./assets/socialMedia.png";
import recepitsPng from "./assets/recepits.png";
import bureaucracyPng from "./assets/bureaucracy.png";
import conversionPng from "./assets/conversion.png";
import customerPng from "./assets/customer.png";
import platformPng from "./assets/platform.png";
import timeSaverPng from "./assets/timeSaver.png";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const COLOR_GRADIENT =
  "linear-gradient(227.23deg, #BCC2FC 16.89%, #E9F8F9 54.53%, #E9F8F9 55.67%, #F5F3F8 99.31%)";

type HighLightTextBoxProps = {
  icon: string;
  text: string;
  backGroundColor: string;
  textColor: string;
};

type OperationComparisonCellProps = {
  text: any;
  icon: string;
  desktopReverse: boolean;
};

function OperationComparisonCell({
  text,
  icon,
  desktopReverse,
}: OperationComparisonCellProps) {
  return (
    <TableCell align="center" sx={{ borderBottom: "1px solid #A29FFF" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: desktopReverse
            ? { xs: "row", md: "row-reverse" }
            : "row",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "left",
            fontSize: { xs: "16px !important", md: "20px !important" },
            lineHeight: { xs: "24px", md: "30px" },
            color: "secondary.main",
            width: { xs: "182px", md: "257px" },
          }}
        >
          {text}
        </Typography>
        <img src={icon} alt="Icon" />
      </Container>
    </TableCell>
  );
}

function HighLightTextBox({
  icon,
  text,
  backGroundColor,
  textColor,
}: HighLightTextBoxProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "80px",
        background: backGroundColor,
        borderRadius: "6px",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          columnGap: "19px",
        }}
      >
        <img style={{ width: "22px", height: "auto" }} src={icon} alt="Icon" />
        <Typography sx={{ fontSize: "20px", color: textColor }}>
          {text}
        </Typography>
      </Container>
    </Box>
  );
}

function Headline() {
  return (
    <Box
      sx={{
        background: COLOR_GRADIENT,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
          // Padding top accounts for the fixed header height
          pt: "120px",
          pb: "80px",
          rowGap: "80px",
        }}
      >
        <Box sx={{ maxWidth: "510px" }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "45px !important",
              fontWeight: "bold",
              color: "secondary.main",
              mb: "14px",
            }}
          >
            Merchant
          </Typography>
          <Typography sx={{ fontSize: "20px", color: "grey.400" }}>
            Effortless word of mouth influencer marketing with guaranteed
            revenue and positive impact
          </Typography>

          <Box my="20px" />
          <EmailWaitList
            text="Contact Sales"
            successMessage="Our sales will be in contact shortly!"
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            display: "flex",
            justifyContent: "center",
            pr: "40px",
          }}
        >
          <Box m="15px" />
          <img
            style={{ width: "100%", height: "auto" }}
            src={MerchantHeaderImg}
            alt="Logo"
          />
        </Box>
      </Container>
    </Box>
  );
}

function FlowComparison() {
  return (
    <Box sx={{ py: "56px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "45px !important",
            fontWeight: "bold",
            color: "secondary.main",
            mb: "14px",
          }}
        >
          In & Out Comparison
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-between",
            alignItems: "center",
            pt: "50px",
            pb: "80px",
            columnGap: "19px",
            rowGap: "10px",
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "590px",
              height: "440px",
              border: "4px solid",
              borderColor: "#A29FFF",
              borderRadius: "10px",
              boxShadow: "0px 10px 20px rgba(219, 95, 134, 0.1)",
            }}
          >
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                py: "60px",
                rowGap: "10px",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: "30px !important",
                  fontWeight: "bold",
                  color: "secondary.main",
                  mb: "14px",
                }}
              >
                Our offer
              </Typography>
              <HighLightTextBox
                icon={GreenCheckMark}
                text="Free to Find Your Influencers"
                backGroundColor="#A29FFF"
                textColor="#FFFFFF"
              />
              <HighLightTextBox
                icon={GreenCheckMark}
                text="Pay You 30-80% for the Meal"
                backGroundColor="#A29FFF"
                textColor="#FFFFFF"
              />
              <HighLightTextBox
                icon={GreenCheckMark}
                text="Posts with Defined High Quality"
                backGroundColor="#A29FFF"
                textColor="#FFFFFF"
              />
            </Container>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "590px",
              height: "440px",
              border: "1px solid",
              borderColor: "#A29FFF",
              borderRadius: "10px",
            }}
          >
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                py: "60px",
                rowGap: "10px",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: "30px !important",
                  fontWeight: "bold",
                  color: "secondary.main",
                  mb: "14px",
                }}
              >
                Others
              </Typography>
              <HighLightTextBox
                icon={GreyCheckMark}
                text="$$ For Agency to Find Influencers"
                backGroundColor="rgba(238, 234, 248, 0.75)"
                textColor="#797979"
              />
              <HighLightTextBox
                icon={GreyCheckMark}
                text="Free Meal for Influencers"
                backGroundColor="rgba(238, 234, 248, 0.75)"
                textColor="#797979"
              />
              <HighLightTextBox
                icon={GreyCheckMark}
                text="Posts with Unknown Quality"
                backGroundColor="rgba(238, 234, 248, 0.75)"
                textColor="#797979"
              />
            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function OperationComparison() {
  return (
    <Box
      sx={{
        background: "rgba(238, 234, 248, 0.75)",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          pt: "80px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "45px !important",
            fontWeight: "bold",
            color: "secondary.main",
          }}
        >
          Operation Comparison
        </Typography>
        <Container
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: "center",
            pt: "46px",
            pb: "80px",
            pl: "0px",
            pr: "0px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
              height: { xs: "477px", md: "620px" },
              backgroundColor: "#EDE7FD",
              border: "4px solid #A29FFF",
              borderRight: { xs: "4px solid #A29FFF", md: "2px solid #A29FFF" },
              borderBottom: {
                xs: "2px solid #A29FFF",
                md: "4px solid #A29FFF",
              },
              borderRadius: {
                xs: "10px 10px 0px 0px",
                md: "10px 0px 0px 10px",
              },
            }}
          >
            <Table
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell align="center" sx={{ borderBottom: "0px" }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "24px", md: "30px !important" },
                        fontWeight: "bold",
                        color: "secondary.main",
                      }}
                    >
                      Our Offer
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: "1px solid #A29FFF" }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        pl: { xs: "16px", md: "24px" },
                        textAlign: "left",
                        fontSize: {
                          xs: "16px !important",
                          md: "20px !important",
                        },
                        lineHeight: { xs: "24px", md: "30px" },
                        color: "secondary.main",
                        width: { xs: "182px", md: "257px" },
                      }}
                    >
                      <b>Machine Learning</b> System Find the <b>Best</b>{" "}
                      Matches
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <OperationComparisonCell
                    text={
                      <div>
                        <b>Influencers</b> Come In like Regular Customers
                      </div>
                    }
                    icon={OperationIconPng}
                    desktopReverse={false}
                  />
                </TableRow>
                <TableRow>
                  <OperationComparisonCell
                    text={
                      <div>
                        <b>Influencers</b> Improvise for the Most Impact
                      </div>
                    }
                    icon={OperationIconPng}
                    desktopReverse={false}
                  />
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: "1px solid #A29FFF" }}
                  >
                    <Container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Box />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            textAlign: "left",
                            fontSize: "14px !important",
                            color: "#39383F",
                            width: "83px",
                            lineHeight: "18px",
                          }}
                        >
                          Time Spent
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            mt: "px",
                            textAlign: "center",
                            fontSize: "18px !important",
                            color: "#39383F",
                            width: "103px",
                            lineHeight: "18px",
                          }}
                        >
                          2 Minutes
                        </Typography>
                      </Box>
                    </Container>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: { xs: "477px", md: "620px" },
              maxWidth: "600px",
              border: "4px solid #A29FFF",
              backgroundColor: "#FFFFFF",
              borderLeft: { xs: "4px solid #A29FFF", md: "2px solid #A29FFF" },
              borderTop: { xs: "2px solid #A29FFF", md: "4px solid #A29FFF" },
              borderRadius: {
                xs: "0px 0px 10px 10px",
                md: "0px 10px 10px 0px",
              },
            }}
          >
            <Table
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell align="center" sx={{ borderBottom: "0px" }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "30px !important",
                        fontWeight: "bold",
                        color: "secondary.main",
                      }}
                    >
                      Others
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <OperationComparisonCell
                    text={
                      <div>
                        <b>You</b> Screen and Pick Influencers
                      </div>
                    }
                    icon={OperationIcon2Png}
                    desktopReverse={true}
                  />
                </TableRow>
                <TableRow>
                  <OperationComparisonCell
                    text={
                      <div>
                        <b>You</b> Prepare for the Influencers Event/Meal
                      </div>
                    }
                    icon={OperationIcon4Png}
                    desktopReverse={true}
                  />
                </TableRow>
                <TableRow>
                  <OperationComparisonCell
                    text={
                      <div>
                        <b>You</b> Work with Influencers to create Contents
                      </div>
                    }
                    icon={OperationIcon2Png}
                    desktopReverse={true}
                  />
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: "1px solid #A29FFF" }}
                  >
                    <Container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: { xs: "row", md: "row-reverse" },
                      }}
                    >
                      <Box />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            textAlign: "left",
                            fontSize: "14px !important",
                            color: "#39383F",
                            width: "83px",
                            lineHeight: "18px",
                          }}
                        >
                          Time Spent
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            mt: "px",
                            textAlign: "center",
                            fontSize: "18px !important",
                            color: "#39383F",
                            width: "103px",
                            lineHeight: "18px",
                          }}
                        >
                          5 Hours
                        </Typography>
                      </Box>
                    </Container>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}

type TopBarButtonProps = {
  onClick: React.MouseEventHandler;
  borderRadius: string;
  text: string;
  shouldHightLight: boolean;
  borderRight: string;
};

function TopBarButton({
  onClick,
  borderRadius,
  text,
  shouldHightLight,
  borderRight,
}: TopBarButtonProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #B4B2EB",
        borderRight: { borderRight },
        height: "52px",
        width: "33%",
        borderRadius: { borderRadius },
        background: shouldHightLight ? "#DB5F86" : "#FFFFFF",
        cursor: "pointer",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          textAlign: "center",
          fontSize: "16px !important",
          color: shouldHightLight ? "#FFFFFF" : "#291A64",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

type IconAndTextProps = {
  text: string;
  icon: string;
  pt: string;
};

function IconAndText({ text, icon, pt }: IconAndTextProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <img
        style={{ paddingTop: isMobile ? "0px" : pt }}
        src={icon}
        alt="Icon"
      />
      <Typography
        sx={{
          textAlign: "center",
          width: "305px",
          lineHeight: "30px",
          fontSize: "16px !important",
          color: "#797979",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

type BenefitsContentProps = {
  text1: string;
  text2: string;
  icon: string;
};

function BenefitsContent({ text1, text2, icon }: BenefitsContentProps) {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        py: "35px",
        rowGap: "10px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <img width={isMobile ? "43px" : "80px"} src={icon} alt="Icon" />
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Typography
            sx={{
              width: { xs: "196px", md: "360px" },
              textAlign: "left",
              fontSize: { xs: "18px", md: "20px" },
              color: "secondary.main",
            }}
          >
            {text1}
          </Typography>
          <Typography
            sx={{
              width: { xs: "196px", md: "360px" },
              lineHeight: "26px",
              fontSize: "16px !important",
              color: "#797979",
            }}
          >
            {text2}
          </Typography>
        </Container>
      </Container>
    </Container>
  );
}

type tagTextProps = {
  text: string;
};

function TagText({ text }: tagTextProps) {
  return (
    <Box
      sx={{
        display: "flex",
        background: "rgba(238, 234, 248, 0.75)",
        borderRadius: "6px",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Typography sx={{ fontSize: "15px" }}>{text}</Typography>
    </Box>
  );
}

const costOrProfitText = function (
  a: string,
  b: string,
  c: string,
  textColor: string
) {
  return (
    <span>
      {a}
      <span style={{ color: textColor }}>{b}</span> {c}
    </span>
  );
};

const tableContentTiers = [
  [
    [
      "Product",
      "Others (free meal)",
      "Impact (entry)",
      "Impact (standard)",
      "Impact (premium)",
      "Fame (entry)",
      "Fame (standard)",
      "Fame (premium)",
    ],
    ["Material Cost", "$15", "-$15", "-$15", "-$15", "-$15", "-$15", "-$15"],
    ["Bill of $50", "-$0", "$50", "$50", "$50", "$50", "$50", "$50"],
    ["Tips", "$10", "$7.5", "$7.5", "$7.5", "$7.5", "$7.5", "$7.5"],
    ["Reimbursement", "$0", "-$17.5", "-$22.5", "-$10", "$0", "$0", "$0"],
    ["Post Bonus", "0", "-$5", "-$20", "$0", "-$20", "-$40", "$0"],
    [
      "Cost or profit",
      costOrProfitText("-15+10=", "-$5", "(+ agency fee)", "#DB5F86"),
      costOrProfitText("-15+50+7.5-17.5-5=", "$20", "", "#9DD1A2"),
      costOrProfitText("-15+50+7.5-22.5-20=", "$0", "", "#9DD1A2"),
      costOrProfitText("-15+50+7.5-10=", "$32.5", "", "#9DD1A2"),
      costOrProfitText("-15+50+7.5-20=", "$22.5", "", "#9DD1A2"),
      costOrProfitText("-30+100+15-40=", "$2.5", "", "#9DD1A2"),
      costOrProfitText("-30+100+15=", "$42.5", "", "#9DD1A2"),
    ],
  ],
  [
    [
      "Product",
      "Others (free meal)",
      "Impact (entry)",
      "Impact (standard)",
      "Impact (premium)",
      "Fame (entry)",
      "Fame (standard)",
      "Fame (premium)",
    ],
    ["Material Cost", "-$30", "-$30", "-$30", "-$30", "-$30", "-$30", "-$30"],
    ["Bill of $100", "-$0", "$100", "$100", "$100", "$100", "$100", "$100"],
    ["Tips", "$20", "$15", "$15", "$15", "$15", "$15", "$15"],
    ["Reimbursement", "$0", "-$35", "-$45", "-$20", "$0", "$0", "$0"],
    ["Post Bonus", "0", "-$5", "-$20", "$0", "-$20", "-$40", "$0"],
    [
      "Cost or profit",
      costOrProfitText("-$30+$20=", "-$10", "(+ agency fee)", "#DB5F86"),
      costOrProfitText("-$30+$100+$15-$35-$5=", "$45", "", "#9DD1A2"),
      costOrProfitText("-$30+$100+$15-$45-$20=", "$20", "", "#9DD1A2"),
      costOrProfitText("-$30+$100+$15-$20=", "$65", "", "#9DD1A2"),
      costOrProfitText("-$30+$100+$15-$20=", "$65", "", "#9DD1A2"),
      costOrProfitText("-$30+$100+$15-$40=", "$45", "", "#9DD1A2"),
      costOrProfitText("-$30+$100+$15=", "$85", "", "#9DD1A2"),
    ],
  ],
  [
    [
      "Product",
      "Others (free meal)",
      "Impact (entry)",
      "Impact (standard)",
      "Impact (premium)",
      "Fame (entry)",
      "Fame (standard)",
      "Fame (premium)",
    ],
    ["Material Cost", "-$60", "-$60", "-$60", "-$60", "-$60", "-$60", "-$60"],
    ["Bill of $200", "-$0", "$200", "$200", "$200", "$200", "$200", "$200"],
    ["Tips", "$40", "$30", "$30", "$30", "$30", "$30", "$30"],
    ["Reimbursement", "$0", "-$60", "-$60", "-$40", "$0", "$0", "$0"],
    ["Post Bonus", "0", "-$5", "-$20", "$0", "-$20", "-$40", "$0"],
    [
      "Cost or profit",
      costOrProfitText("-$30+$100+$15=", "-$20", "(+ agency fee)", "#DB5F86"),
      costOrProfitText("-$60+$200+$30-$60-$5=", "$105", "", "#9DD1A2"),
      costOrProfitText("-$60+$200+$30-$60-$5=", "$90", "", "#9DD1A2"),
      costOrProfitText("-$60+$200+$30-$40=", "$130", "", "#9DD1A2"),
      costOrProfitText("-$60+$200+$30-$40=", "$150", "", "#9DD1A2"),
      costOrProfitText("-$60+$200+$30-$40=", "$130", "", "#9DD1A2"),
      costOrProfitText("-$60+$200+$30=", "$170", "", "#9DD1A2"),
    ],
  ],
];
/* ScenarioBasedPricing hidden for now */
function ScenarioBasedPricing() {
  const [topBarStates, settopBarStates] = useState([true, false, false]);
  const [tableContent, setTableContent] = useState(tableContentTiers[0]);
  const [mobileCol, setMobileCol] = React.useState("6");

  const handleChange = (event: SelectChangeEvent) => {
    setMobileCol(event.target.value as string);
  };
  return (
    <Box sx={{ py: "56px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            fontSize: { xs: "34px !important", md: "45px !important" },
            fontWeight: "bold",
            color: "secondary.main",
            mb: "14px",
          }}
        >
          Scenario Based Pricing Comparison
        </Typography>
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            fontSize: "20px !important",
            color: "#797979",
            width: { xs: "318px", md: "745px" },
            lineHeight: { xs: "26px", md: "32px" },
          }}
        >
          The current mainstream practice is to offer free meals or service.
          Below we compare it with our products.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
            maxWidth: "477px",
            pt: "60px",
            pb: "40px",
          }}
        >
          <TopBarButton
            onClick={() => {
              settopBarStates([true, false, false]);
              setTableContent(tableContentTiers[0]);
            }}
            borderRadius="5px 0px 0px 5px"
            text="$50"
            shouldHightLight={topBarStates[0]}
            borderRight="0px"
          />
          <TopBarButton
            onClick={() => {
              settopBarStates([false, true, false]);
              setTableContent(tableContentTiers[1]);
            }}
            borderRadius="0px"
            text="$100"
            shouldHightLight={topBarStates[1]}
            borderRight="0px"
          />
          <TopBarButton
            onClick={() => {
              settopBarStates([false, false, true]);
              setTableContent(tableContentTiers[2]);
            }}
            borderRadius="0px 5px 5px 0px"
            text="$200"
            shouldHightLight={topBarStates[2]}
            borderRight="1px solid #B4B2EB"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "flex-start",
            columnGap: "0px",
            width: "100%",
            maxWidth: "1200px",
            boxShadow: "0px 10px 20px rgba(219, 95, 134, 0.1)",
          }}
        >
          {isMobile ? (
            <>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px !important",
                  width: "40%",
                }}
              >
                {tableContent[0].map((content, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "53px",
                      border: "1px solid rgba(238, 234, 248, 0.75)",
                      width: "100%",
                      backgroundColor: "#FBFBFB",
                      borderTopLeftRadius: index === 0 ? "10px" : "0px",
                      borderBottomLeftRadius: index === 7 ? "10px" : "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "13px !important",
                      }}
                    >
                      {content}
                    </Typography>
                  </Box>
                ))}
              </Container>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px !important",
                  width: "60%",
                }}
              >
                <Select
                  sx={{
                    width: "101%",
                    boxSizing: "unset !important",
                    borderRadius: "0px 10px 0px 0px",
                    height: "55px",
                    fontSize: "13px !important",
                    textAlign: "center",
                  }}
                  value={mobileCol}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>{tableContent[1][0]}</MenuItem>
                  <MenuItem value={2}>{tableContent[2][0]}</MenuItem>
                  <MenuItem value={3}>{tableContent[3][0]}</MenuItem>
                  <MenuItem value={4}>{tableContent[4][0]}</MenuItem>
                  <MenuItem value={5}>{tableContent[5][0]}</MenuItem>
                  <MenuItem value={6}>{tableContent[6][0]}</MenuItem>
                </Select>
                {tableContent[Number(mobileCol)]
                  .slice(1, 8)
                  .map((content, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "53px",
                        border: "1px solid rgba(238, 234, 248, 0.75)",
                        width: "100%",
                        borderBottomRightRadius: index === 6 ? "10px" : "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "13px !important",
                        }}
                      >
                        {content}
                      </Typography>
                    </Box>
                  ))}
              </Container>
            </>
          ) : (
            tableContent.map((cols, i) => (
              <Container
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px !important",
                }}
              >
                {cols.map((content, j) => (
                  <Box
                    key={j}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: i === 6 || i === 0 ? "240px" : "100%",
                      height: "53px",
                      border: "1px solid rgba(238, 234, 248, 0.75)",
                      backgroundColor: i === 0 ? "#FBFBFB" : undefined,
                      borderTop: j === 0 ? "1px solid #B4B2EB" : undefined,
                      borderRight: i === 6 ? "1px solid #B4B2EB" : undefined,
                      borderLeft: i === 0 ? "1px solid #B4B2EB" : undefined,
                      borderBottom: j === 7 ? "1px solid #B4B2EB" : undefined,
                      borderTopLeftRadius:
                        i === 0 && j === 0 ? "10px" : undefined,
                      borderBottomLeftRadius:
                        i === 0 && j === 7 ? "10px" : undefined,
                      borderTopRightRadius:
                        i === 6 && j === 0 ? "10px" : undefined,
                      borderBottomRightRadius:
                        i === 6 && j === 7 ? "10px" : undefined,
                    }}
                  >
                    <Typography
                      sx={{
                        color: j === 1 ? "#797979" : undefined,
                        fontWeight: j === 0 ? "bold" : undefined,
                        textAlign: "center",
                        fontSize: "15px !important",
                      }}
                    >
                      {content}
                    </Typography>
                  </Box>
                ))}
              </Container>
            ))
          )}
        </Box>
        <Box
          sx={{
            pt: "20px",
            pb: "50px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "5px 10px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "18px !important",
            }}
          >
            Assumptions:
          </Typography>
          <TagText text="An influencer with friends spend $50/$100/$200 and post for 70 likes on Instagram" />
          <TagText text="30% material cost" />
          <TagText text="Higher tips for free meal" />
          <TagText text="Recommended reimbursement limit at $60" />
        </Box>

        <Box sx={{ pt: "50px" }} />
      </Container>
    </Box>
  );
}

function HowItWorks() {
  return (
    <Box
      sx={{
        width: "100%",
        background: "rgba(238, 234, 248, 0.75)",
        borderRadius: "10px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          pt: "80px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "45px !important",
            fontWeight: "bold",
            color: "secondary.main",
          }}
        >
          How it works
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-between",
            alignItems: "center",
            pt: "50px",
            pb: "80px",
            columnGap: "19px",
            rowGap: "10px",
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <IconAndText
            text="Our influencers visit your business as ordinary customers."
            icon={HandShakePng}
            pt="0px"
          />
          <IconAndText
            text="They post on their individual social media accounts to promote your services/products."
            icon={SocialMediaPng}
            pt="30px"
          />
          <IconAndText
            text="Collect everything (receipts, posts) along the way for your verification."
            icon={recepitsPng}
            pt="0px"
          />
        </Box>
      </Container>
    </Box>
  );
}
function HowItHelps() {
  return (
    <Box sx={{ py: "56px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "45px !important",
            fontWeight: "bold",
            color: "secondary.main",
            mb: "14px",
          }}
        >
          How it helps
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-between",
            alignItems: "stretch",
            pt: "50px",
            pb: "80px",
            columnGap: "19px",
            rowGap: "30px",
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "30px !important",
                fontWeight: "bold",
                color: "secondary.main",
                pb: "20px",
              }}
            >
              Direct Benefits
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0px",
                width: "100%",
                maxWidth: "590px",
                border: "1px solid",
                borderColor: "#A29FFF",
                borderRadius: "10px",
                boxShadow: "0px 10px 20px rgba(219, 95, 134, 0.1)",
              }}
            >
              <BenefitsContent
                icon={HealthPng}
                text1="Healthy Cycle & Direct Impact"
                text2="Keep your business rolling. Fill unused capacity. More attractive to pedestrians."
              />
              <BenefitsContent
                icon={customerPng}
                text1="More Visibility & More Customers"
                text2="More reviews, higher ratings and frequent posts mean more customers."
              />
              <BenefitsContent
                icon={conversionPng}
                text1="High Conversion & Sticky Patrons"
                text2="Friends enjoy similar things. More convincing from a friend than advertisements."
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "30px !important",
                fontWeight: "bold",
                color: "secondary.main",
                pb: "20px",
              }}
            >
              Hidden Benefits
            </Typography>
            <Box
              sx={{
                width: "100%",
                maxWidth: "590px",
                border: "1px solid",
                borderColor: "#A29FFF",
                borderRadius: "10px",
                boxShadow: "0px 10px 20px rgba(219, 95, 134, 0.1)",
                flexGrow: 1,
              }}
            >
              <BenefitsContent
                icon={timeSaverPng}
                text1="Time Saver"
                text2="No more individual bargains with influencers."
              />
              <BenefitsContent
                icon={bureaucracyPng}
                text1="Skip Bureaucracy"
                text2="No need to deal with opaque PR and Marketing agencies."
              />
              <BenefitsContent
                icon={platformPng}
                text1="All Platforms"
                text2="Not limited to a specific social media."
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
export default function MerchantsV2() {
  return (
    <DefaultHeaderFooter>
      <Headline />
      <FlowComparison />
      <OperationComparison />
      <HowItWorks />
      <HowItHelps />
    </DefaultHeaderFooter>
  );
}
