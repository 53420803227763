import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DefaultHeaderFooter from "../DefaultHeaderFooter";
import { useState } from "react";
import { Dialog, LinearProgress } from "@mui/material";
import Headline from "../Headline";

const tiers = [
  {
    title: "Guest",
    price: "30%",
    bonus: "$0",
    display: "none",
    description: [
      "Encourage people to bring friends",
      "Charged after you verify",
      "Exclude tips and tax",
      "Email support",
    ],
    buttonText: "Sign up for beta",
  },
  {
    title: "Impact",
    subheader: "Most popular",
    primary: true,
    price: "35%+",
    bonus: "$5+",
    display: "flex",
    description: [
      "Everything in Guest",
      "Posts on social media",
      "Priority email support",
    ],
    buttonText: "Sign up for beta",
  },
  {
    title: "Fame",
    price: "0%",
    bonus: "$20+",
    display: "flex",
    description: [
      "You keep all the revenue",
      "Pay only for the influences",
      "Priority email support",
    ],
    buttonText: "Sign up for beta",
  },
  {
    title: "VIP",
    price: "100%",
    bonus: "$100+",
    display: "flex",
    description: [
      "Top tier influencers",
      "Customizable terms",
      "High standards verified by you",
      "Phone & email support",
    ],
    buttonText: "Sign up for beta",
  },
];

function Column({ primary, text }: { text: string; primary?: boolean }) {
  const color = primary ? "#FFFFFF" : undefined;
  const fontWeight = primary ? "bold" : undefined;
  return (
    <Box
      sx={{
        px: { xs: "15px", md: "40px" },
        flex: "1 1 33%",
        py: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ color, fontWeight, fontSize: { xs: "12px", md: "16px" } }}
      >
        {text}
      </Typography>
    </Box>
  );
}

function ThreeColsRow({
  col1,
  col2,
  col3,
}: {
  col1: string;
  col2: string;
  col3: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        borderRadius: "3px",
        background: "#FBFBFF",
        alignItems: "stretch",
        my: "7px",
      }}
    >
      <Column text={col1} />
      <Box
        sx={{
          width: "0px",
          border: "1px solid rgba(238, 234, 248, 0.75)",
        }}
      />
      <Column text={col2} />
      <Box
        sx={{
          width: "0px",
          border: "1px solid rgba(238, 234, 248, 0.75)",
        }}
      />
      <Column text={col3} />
    </Box>
  );
}

function ComboPricing() {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "80px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "34px", md: "50px" },
          fontWeight: "bold",
          color: "secondary.main",
        }}
      >
        Combo Pricing
      </Typography>

      <Box m="15px" />

      <Typography
        sx={{
          fontSize: { xs: "16px", md: "20px" },
          fontWeight: "400",
          color: "secondary.main",
          textAlign: "center",
        }}
      >
        Guaranteed revenue + guaranteed social media impact
      </Typography>

      <Box m="10px" />

      {/* Combo pricing table */}
      <Box sx={{ width: "100%", maxWidth: "800px" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            borderRadius: "15px",
            backgroundColor: "primary.main",
            alignItems: "stretch",
            my: "20px",
            py: { xs: "4px", md: "20px" },
          }}
        >
          <Column primary text="Product Type" />
          <Column primary text="Likes Multiplier" />
          <Column primary text="Revenue Multiplier" />
        </Box>

        <ThreeColsRow col1="Marketing" col2="1.45" col3="1.2" />
        <ThreeColsRow col1="Balanced" col2="1.15" col3="1.6" />
        <ThreeColsRow col1="Revenue" col2="1" col3="1.9" />
        <ThreeColsRow col1="Industry Average" col2="0.5" col3="0" />

        {/* Footnote */}
        <Typography
          sx={{
            color: "grey.400",
            fontSize: { xs: "12px", md: "16px" },
            mt: { xs: "20px", md: "40px" },
          }}
        >
          <b>Example:</b> For each $100 in "MARKETING" Combo, we guarantee 145
          likes and $120 revenue
          <br />
          (combos start at $500 per month)
        </Typography>
      </Box>
    </Container>
  );
}

function StandardPricing() {
  const [formTier, setFormTier] = useState<string>("");
  const formOpen = !!formTier;

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Dialog
        open={formOpen}
        onClose={() => setFormTier("")}
        fullWidth
        maxWidth="md"
      >
        <iframe
          title="sign up beta form"
          src={`https://docs.google.com/forms/d/e/1FAIpQLSfvRiVoouZlnPU7iYerV1dydXLDjmt8wJgzXyvhay1IKar0wg/viewform?usp=pp_url&entry.132536597=${formTier}&embedded=true`}
          style={{ maxWidth: "100%", width: "auto", height: "1340px" }}
        >
          <LinearProgress />
        </iframe>
      </Dialog>

      {/* Section heading */}
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "34px", md: "50px" },
          fontWeight: "bold",
          color: "secondary.main",
        }}
      >
        Standard Pricing
      </Typography>

      <Box m="15px" />

      <Typography
        sx={{
          fontSize: { xs: "16px", md: "20px" },
          fontWeight: "400",
          color: "secondary.main",
          textAlign: "center",
        }}
      >
        No upfront, monthly or hidden fees. No contracts. Start/Stop anytime.{" "}
        <br />
        We pay you 30%~100% of the bill to market for you!
      </Typography>
      <Box sx={{ m: { xs: "20px", md: "40px" } }} />

      {/* Product types as columns */}
      <Grid container spacing={3} alignItems="stretch">
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs={12}
            md={3}
            sx={{ margin: { md: tier.primary ? "-24px 0 0 0" : "" } }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardHeader
                title={tier.title}
                titleTypographyProps={{
                  align: "center",
                  variant: "h2",
                  color: tier.primary ? "white" : "text.primary",
                }}
                subheader={
                  <Typography sx={{ textAlign: "center" }}>
                    {tier.subheader}
                  </Typography>
                }
                action={tier.primary ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: (theme) => {
                    if (tier.primary) {
                      return "primary.main";
                    }

                    return "secondary.light";
                  },

                  color: tier.primary ? "white" : undefined,
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h2" color="text.primary">
                    {tier.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /bill
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: tier.display,
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography
                    component="h5"
                    variant="body2"
                    color="text.primary"
                  >
                    plus
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: tier.display,
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h2" color="text.primary">
                    {tier.bonus}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /post
                  </Typography>
                </Box>
                {tier.description.map((line) => (
                  <Typography variant="subtitle1" align="center" key={line}>
                    {line}
                  </Typography>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.primary ? "contained" : "outlined"}
                  sx={{ color: tier.primary ? "white" : "primary.main" }}
                  onClick={() => setFormTier(tier.title)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default function Pricing() {
  return (
    <DefaultHeaderFooter>
      <Headline title="Pricing" />
      <ComboPricing />

      {/* <Box
        sx={{
          background: "rgba(238, 234, 248, 0.25)",
          py: "80px",
        }}
      >
        <StandardPricing />
      </Box> */}
    </DefaultHeaderFooter>
  );
}
