import { useEffect, useState } from "react";
// Adapted from: https://stackoverflow.com/a/63249329
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function usePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      // https://analytics.google.com/analytics/web/provision/?authuser=5#/a220485447w303777541p260963534/admin/property/settings
      ReactGA.initialize("UA-220485447-1");
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}

export default function PageTracker() {
  usePageTracking();
  return null;
}
