import { Box, Container, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Button, LoadingButton, toast } from "aagent-ui";
import { Storage } from "aws-amplify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import DefaultHeaderFooter from "./DefaultHeaderFooter";
import { withAuthenticator } from "@aws-amplify/ui-react";

const ADMIN_EMAILS = [
  "simon@aagent.co",
  "mike@aagent.co",
  "siranliu@aagent.co",
];

const EditContent = withAuthenticator(
  ({ initialEditorState, label, user, signOut }: any) => {
    if (!ADMIN_EMAILS.includes(user.attributes.email)) {
      return <Box>Forbidden</Box>;
    }

    const [editorState, setEditorState] =
      useState<EditorState>(initialEditorState);
    const [loading, setLoading] = useState<boolean>(false);

    const updateContent = () => {
      setLoading(true);
      const content = editorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      const draftRaw = JSON.stringify(rawObject); //<- save this to database
      Storage.put(`${label}.json`, draftRaw, {
        level: "public",
        acl: "public-read",
      })
        .then(() => toast.success("Updated"))
        .catch((err) => toast.error(String(err)))
        .finally(() => setLoading(false));
    };

    return (
      <Container>
        <Button sx={{ mb: "30px" }} onClick={signOut}>
          Log out
        </Button>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
        />
        <Box m="30px" />

        <LoadingButton loading={loading} fullWidth onClick={updateContent}>
          Update
        </LoadingButton>
      </Container>
    );
  }
);

export default function AdminEditableContent({ label }: { label: string }) {
  const [editorState, setEditorState] = useState<EditorState>();
  const location = useLocation();
  const isEditing = location.pathname.includes("/admin-edit");

  useEffect(() => {
    fetch(
      `https://anonymous-agent-web-s3.s3.amazonaws.com/public/${label}.json`
    )
      .then((response) => response.text())
      .then(async (result) => {
        setEditorState(
          EditorState.push(
            EditorState.createEmpty(),
            convertFromRaw(JSON.parse(result)),
            "remove-range"
          )
        );
      })
      .catch((err) => {
        toast.error(String(err));
        setEditorState(EditorState.createEmpty());
      });
  }, [label]);

  if (!editorState) {
    return (
      <DefaultHeaderFooter>
        <LinearProgress />
      </DefaultHeaderFooter>
    );
  }

  return (
    <DefaultHeaderFooter>
      <Box m="80px" />
      {!isEditing && (
        <Container>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorState}
            readOnly
            toolbarHidden
          />
        </Container>
      )}

      {isEditing && (
        <EditContent initialEditorState={editorState} label={label} />
      )}
    </DefaultHeaderFooter>
  );
}
