import DefaultHeaderFooter from "./DefaultHeaderFooter";
import * as React from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
export default function Careers() {
  //need to be reading from a central config instead of hard-coded url
  const api_url = "https://4nevvp139c.execute-api.us-east-1.amazonaws.com/Prod";
  const [name, setName] = React.useState("Vendor Name on Google Map");
  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const [zip, setZip] = React.useState("Zipcode");
  const zipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip(event.target.value);
  };
  const [cred, setCred] = React.useState("Credential");
  const credChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCred(event.target.value);
  };
  function check_name() {
    // send post request to check whether name already researched
    var vendorID = name + "_" + zip;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ vid: vendorID, sid: cred }),
    };
    // console.log("starting request...");
    // console.log(requestOptions);
    fetch(api_url, requestOptions)
      .then((response) => response.text())
      .then((data) => {
        alert(data);
      });
  }

  return (
    <DefaultHeaderFooter>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        alignItems="center"
      >
        <TextField
          id="name"
          label="Name on Google Map"
          value={name}
          onChange={nameChange}
          variant="outlined"
        />
        <TextField
          id="zip"
          value={zip}
          onChange={zipChange}
          variant="outlined"
        />
        <TextField
          id="cred"
          value={cred}
          onChange={credChange}
          variant="outlined"
        />
        <Button variant="contained" onClick={check_name}>
          Check Avalaibility
        </Button>
      </Box>
    </DefaultHeaderFooter>
  );
}
