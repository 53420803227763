import { Box, Container, Typography } from "@mui/material";
import UscImage from "./assets/partners/usc.png";
import NyuImage from "./assets/partners/nyu.jpeg";
import PlugAndPlayImage from "./assets/partners/plug_and_play.png";
import NuMediaImage from "./assets/partners/nu_media.png";
import AmazonImage from "./assets/partners/amazon.jpeg";
import FirstBaseImage from "./assets/partners/firstbase.png";
import SeamoreImage from "./assets/partners/seamore.jpeg";
import EmImage from "./assets/partners/em.jpeg";
import "./slider.css";

const GoogleImage = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png";

type SlideProps = {
  src: string;
  height?: number;
  width?: number;
};

function Slide({ src, height = 100 }: SlideProps) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100",
        alignItems: "center",
        mx: "20px",
      }}
    >
      <img src={src} height={height} alt="" />
    </Box>
  );
}

export default function PartnersSection() {
  return (
    <div className="slider">
      <Container sx={{ overflow: "hidden" }}>
        {/* Section title */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h1" sx={{ fontWeight: "bold", mb: "12px" }}>
            Partners
          </Typography>
        </Box>

        {/* Slides */}
        <Box
          sx={{
            animation: "scroll 20s linear infinite",
            display: "flex",
          }}
        >
          <Slide src={FirstBaseImage} height={65} />
          <Slide src={UscImage} height={70} />
          <Slide src={NyuImage} height={100} />
          <Slide src={PlugAndPlayImage} height={70} />
          <Slide src={NuMediaImage} height={60} />
          <Slide src={AmazonImage} height={75} />
          <Slide src={GoogleImage} />
          <Slide src={SeamoreImage} height={100} />
          <Slide src={EmImage} height={85} />

          {/* Repeat twice so we will create the "fake" infite scroll experience */}
          <Slide src={FirstBaseImage} height={65} />
          <Slide src={UscImage} height={70} />
          <Slide src={NyuImage} height={100} />
          <Slide src={PlugAndPlayImage} height={70} />
          <Slide src={NuMediaImage} height={60} />
          <Slide src={AmazonImage} height={75} />
          <Slide src={GoogleImage} />
          <Slide src={SeamoreImage} height={100} />
          <Slide src={EmImage} height={85} />
        </Box>
      </Container>
    </div>
  );
}
