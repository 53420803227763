import DefaultHeaderFooter from "../../DefaultHeaderFooter";
import { Box, Link, Container, Typography } from "@mui/material";

export default function ProductResearchIntern() {
  return (
    <DefaultHeaderFooter>
      <Container
        sx={{
          padding: "50px 40px 30px 40px",
          margin: "auto",
          maxWidth: { md: "840px" },
        }}
      >
        <Typography variant="h1" sx={{ my: "40px", textAlign: "center" }}>
          Product Research Analyst
        </Typography>

        <Typography>
          We are a tech startup focusing on effective social media marketing. We
          are looking for part-time Product Research analysts for this spring
          semester. Potential full time opportunities available for summer. This
          is a remote role. This position is unpaid, but you are subject to
          discretionary bonus based on your performance.
        </Typography>

        <Box sx={{ margin: "30px" }}></Box>

        {/* Responsibilities */}
        <Typography variant="h6">Responsibilities</Typography>

        <ul>
          <li>
            <Typography>
              Design and conduct research on various social media platforms
            </Typography>
          </li>

          <li>
            <Typography>
              Document and analyze results from different sources
            </Typography>
          </li>

          <li>
            <Typography>
              Create metrics and models to measure impacts and influences
            </Typography>
          </li>
          <li>
            <Typography>
              Ability to work independently and also in a cross functional team
            </Typography>
          </li>
          <li>
            <Typography>
              Ability to visualize ideas and data with graphs is a plus
            </Typography>
          </li>
        </ul>

        {/* Requirements */}
        <Typography variant="h6">Requirements</Typography>

        <ul>
          <li>
            <Typography>
              Passionate about social media and use multiple platforms
            </Typography>
          </li>

          <li>
            <Typography>
              Proficiency in English. Please indicate if you are fluent in other
              languages
            </Typography>
          </li>

          <li>
            <Typography>
              Fast learner and passion for product research or startups
            </Typography>
          </li>
          <li>
            <Typography>
              Self-motivated with a results-driven approach
            </Typography>
          </li>
          <li>
            <Typography>Knowledge of statistics is a plus</Typography>
          </li>
        </ul>

        {/* Perks */}
        <Typography variant="h6">Perks</Typography>

        <ul>
          <li>
            <Typography>Flexibile schedule</Typography>
          </li>

          <li>
            <Typography>Potential full-time extension</Typography>
          </li>

          <li>
            <Typography>Potential permanent position</Typography>
          </li>

          <li>
            <Typography>Potential leadership opportunities</Typography>
          </li>
        </ul>

        <Box sx={{ margin: "40px" }}></Box>

        <Typography>
          <b>
            To Apply: please email your resume to{" "}
            <Link href="mailto:careers@aagent.co">careers@aagent.co</Link> and
            indicate the role(s) that you are interested in.
          </b>
        </Typography>
      </Container>
    </DefaultHeaderFooter>
  );
}
