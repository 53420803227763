import { Box, Container, Typography, Link } from "@mui/material";
import { Button } from "aagent-ui";
import { Link as RouteLink } from "react-router-dom";
import { COLOR_GRADIENT } from "./constants";
import DefaultHeaderFooter from "./DefaultHeaderFooter";
import StayReal2 from "./assets/stay_real2.jpeg";
import JoinBetaButton from "./JoinBetaButton";
import PartnersSection from "./PartnersSection";

function SectionHeader({ children }: any) {
  return (
    <Typography
      variant="h2"
      sx={{
        color: "secondary.main",
        fontSize: { xs: "36px !important", md: "46px !important" },
      }}
    >
      {children}
    </Typography>
  );
}

function Headline() {
  return (
    <Box
      sx={{
        background: COLOR_GRADIENT,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
          // Padding top accounts for the fixed header height
          pt: "120px",
          pb: "80px",
        }}
      >
        {/* Share to earn text */}
        <Box sx={{ maxWidth: "510px" }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "48px !important", md: "60px !important" },
              fontWeight: "bold",
              color: "secondary.main",
              mb: "14px",
            }}
          >
            Share to earn
          </Typography>
          <Typography sx={{ fontSize: "20px", color: "grey.400" }}>
            Bridge influencers and merchants with AI automated and standardized
            digital marketplace
          </Typography>

          <Box my="20px" />
          <JoinBetaButton />
        </Box>

        <Box my="20px" />

        {/* Image outer circle */}
        <Box
          sx={{
            flex: "0 1 auto",
            display: "flex",
            justifyContent: "center",
            ml: "20px",
            mr: "40px",
            p: "30px",
            position: "relative",
            border: "1px solid rgba(158, 160, 194, 0.2)",
            borderRadius: "50%",
          }}
        >
          {/* Magenta quarter border circle */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: "50%",
              border: "6px solid",
              borderColor: "#A29FFF transparent transparent transparent",
              transform: "rotate(55deg)",
              boxSizing: "border-box",
            }}
          />
          {/* Magenta quarter border circle */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: "50%",
              border: "3px solid",
              borderColor: "transparent transparent #DB5F86 transparent",
              transform: "rotate(25deg)",
              boxSizing: "border-box",
            }}
          />

          {/* 15px circle */}
          <Box
            sx={{
              position: "absolute",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#B4B2EB",
              top: "55%",
              left: "-5%",
            }}
          />

          {/* 10px circle */}
          <Box
            sx={{
              position: "absolute",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#B4B2EB",
              top: "100%",
              left: "66%",
            }}
          />

          {/* 5px circle */}
          <Box
            sx={{
              position: "absolute",
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "#DB5F86",
              top: "0%",
              left: "40%",
            }}
          />

          {/* Image inner circle */}
          <Box
            sx={{
              p: "20px",
              border: "1px solid #B4B2EB",
              borderRadius: "50%",
              position: "relative",
              flex: "1 1 auto",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src="/share4_square.jpeg"
              sx={{
                width: "360px",
                maxWidth: "100%",
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: "#DB5F86",
                top: "75%",
                left: "91%",
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function GetPaidSection() {
  return (
    <Box sx={{ py: "56px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        {/* Get paid text */}
        <Box sx={{ maxWidth: "410px" }}>
          <SectionHeader>
            Get paid <br /> enjoying your life
          </SectionHeader>
          <Box my="20px" />
          <Typography sx={{ fontSize: "16px", color: "grey.400" }}>
            With 20 likes, we deposit $30 to $50 into your account on average.
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "grey.200" }}>
            ($25 average spending for party of 4)
          </Typography>
        </Box>
        <Box m="20px" />

        {/* App screen */}
        <Box sx={{ display: "flex", flex: "0 1 auto", maxWidth: "100%" }}>
          {/* First iphone screenshot */}
          <Box
            sx={{
              flex: "0 1 auto",
              mt: "32px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src="/contract_iphone13.png"
              sx={{
                maxWidth: "223px",
                width: "100%",
              }}
            />
          </Box>

          {/* Second iphone screenshot */}
          <Box m="16px" />
          <Box
            sx={{
              mb: "32px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src="/contract_details_iphone13.png"
              sx={{
                maxWidth: "223px",
                width: "100%",
                overflow: "hidden",
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function PrivacyFirstSection() {
  return (
    <Box sx={{ py: "56px", backgroundColor: "secondary.light" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row-reverse",
          },
        }}
      >
        {/* Privacy first text */}
        <Box sx={{ maxWidth: "410px" }}>
          <SectionHeader>
            Privacy first &<br /> Stay anonymous
          </SectionHeader>
          <Box my="20px" />
          <Typography sx={{ fontSize: "16px", color: "grey.400" }}>
            Merchants will not be able to see the list of influencers and your
            identity. Even your engagement with us remains a secret. We care
            about your privacy, and we go to great lengths to see it protected.
          </Typography>
        </Box>
        <Box m="20px" />

        {/* Image outer circle */}
        <Box
          sx={{
            flex: "0 1 auto",
            display: "flex",
            justifyContent: "center",
            ml: "40px",
            mr: "20px",
            p: "40px",
            position: "relative",
            border: "1px solid rgba(249, 145, 177, 0.3)",
            borderRadius: "50%",
          }}
        >
          {/* 15px purple circle */}
          <Box
            sx={{
              position: "absolute",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#B4B2EB",
              top: "88%",
              left: "17%",
            }}
          />

          {/* 15px magenta circle */}
          <Box
            sx={{
              position: "absolute",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#DB5F86",
              top: "78%",
              left: "88%",
            }}
          />

          {/* 20px circle */}
          <Box
            sx={{
              position: "absolute",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#B4B2EB",
              top: "0%",
              left: "33%",
            }}
          />

          {/* Image inner circle */}
          <Box
            sx={{
              p: "60px",
              border: "1px solid rgba(249, 145, 177, 0.54)",
              borderRadius: "50%",
              position: "relative",
              flex: "1 1 auto",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src="/logo512.png"
              sx={{
                width: "180px",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function StayRealSection() {
  return (
    <Box sx={{ py: "56px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        {/* stay real text */}
        <Box sx={{ maxWidth: "410px" }}>
          <SectionHeader>Stay real</SectionHeader>
          <Box my="20px" />
          <Typography sx={{ fontSize: "16px", color: "grey.400" }}>
            Pictures, content, and which merchants you'd like to engage with
            remain at your helm.
          </Typography>

          <Box my="20px" />
          <JoinBetaButton />
        </Box>
        <Box m="20px" />

        {/* Stay real graphics */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flex: "0 1 auto",
          }}
        >
          <Box
            component="img"
            src="/real1_rect.jpeg"
            sx={{
              width: "220px",
              maxWidth: "52%",
              borderRadius: "20px",
            }}
          />

          <Box
            component="img"
            src={StayReal2}
            sx={{
              width: "220px",
              maxWidth: "52%",
              borderRadius: "20px",
              ml: "-60px",
              mt: "80px",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

function AreYouMerchantBlock() {
  return (
    <Box>
      <Container
        sx={{
          px: { xs: "15px", md: "50px" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            background: COLOR_GRADIENT,
            borderRadius: "20px",
            p: { xs: "30px 20px !important", md: "60px 50px !important" },
            position: "relative",
          }}
        >
          {/* are you merchant text */}
          <Box sx={{ maxWidth: "500px" }}>
            <SectionHeader>Are you a merchant?</SectionHeader>
            <Box my="20px" />
            <Typography sx={{ fontSize: "16px", color: "grey.400" }}>
              Elevate your brand reputation and awareness through word of mouth
              sharing of genuine experiences.
            </Typography>

            <Box my="20px" />

            <Link component={RouteLink} to="/merchants" underline="none">
              <Button>Learn More</Button>
            </Link>
          </Box>
          <Box m="20px" />

          {/* Stay real graphics */}
          <Box
            sx={{
              flex: "1 1 auto",
              display: "flex",
              maxWidth: "100%",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src="/merchant5_rect.jpeg"
              sx={{
                borderRadius: "20px",
                backgroundColor: "grey.100",
                width: "500px",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Container>
      </Container>
    </Box>
  );
}

export default function HomeV2() {
  return (
    <DefaultHeaderFooter>
      <Headline />
      <PartnersSection />
      <GetPaidSection />
      <PrivacyFirstSection />
      <StayRealSection />
      <AreYouMerchantBlock />
    </DefaultHeaderFooter>
  );
}
