import AdminEditableContent from "./AdminEditableContent";

export function Privacy() {
  return <AdminEditableContent label="privacy" />;
}

export function Terms() {
  return <AdminEditableContent label="terms" />;
}

export function PaymentTerms() {
  return <AdminEditableContent label="payment-terms" />;
}
