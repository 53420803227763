import * as React from "react";
import { hydrate, render } from "react-dom";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme, ScrollToTop } from "aagent-ui";

import PageTracker from "./PageTracker";
import AllRoutes from "./AllRoutes";
import "aagent-ui/dist/index.css";
import "./init";

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <PageTracker />
          <ScrollToTop />
          <AllRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root") as any;
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
