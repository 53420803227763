import { Link } from "@mui/material";
import { Button } from "aagent-ui";

export default function JoinBetaButton() {
  return (
    <Link href="/influencers">
      <Button
        sx={{
          borderRadius: "28px",
          p: "14px 20px",
          lineHeight: 1,
          flex: 1,
          minWidth: "137.91px",
        }}
        fullWidth
      >
        Join Beta
      </Button>
    </Link>
  );
}
