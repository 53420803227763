import { Box, Typography } from "@mui/material";
import { ANDROID_APP_URL, INFLUENCERS_URL, IOS_APP_URL } from "./constants";
import { getMobileOperatingSystem, OperatingSystem } from "./deviceUtils";

export default function Beta() {
  const system = getMobileOperatingSystem();
  if (system === OperatingSystem.IOS) {
    setTimeout(() => window.location.replace(IOS_APP_URL), 0);

    return (
      <Box>
        <Typography>Redirecting to App Store ...</Typography>
        <Typography>
          If you are not automatically redirected, click on the link below to
          redirect:{" "}
        </Typography>
        <a href={IOS_APP_URL}>{IOS_APP_URL}</a>
      </Box>
    );
  }

  if (system === OperatingSystem.ANDROID) {
    setTimeout(() => window.location.replace(ANDROID_APP_URL), 0);

    return (
      <Box>
        <Typography>Redirecting to App Store ...</Typography>
        <Typography>
          If you are not automatically redirected, click on the link below to
          redirect:{" "}
        </Typography>
        <a href={ANDROID_APP_URL}>{ANDROID_APP_URL}</a>
      </Box>
    );
  }

  setTimeout(() => window.location.replace(INFLUENCERS_URL), 0);
  return (
    <Box>
      <Typography>Redirecting to the Influencer page ...</Typography>
      <Typography>
        If you are not automatically redirected, click on the link below to
        redirect:{" "}
      </Typography>
      <a href={INFLUENCERS_URL}>{INFLUENCERS_URL}</a>
    </Box>
  );
}
