import { ReactNode } from 'react'
import { Box } from '@mui/material'
import Header from './Header'
import Footer from './Footer'

type Props = {
  children?: ReactNode
}

/**
 * Page template with the default header and footer.
 */
export default function DefaultHeaderFooter({ children }: Props) {
  return (
    <Box sx={{ minWidth: 320 }}>
      <Header />
      {children}
      <Footer />
    </Box>
  )
}