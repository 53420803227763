import { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { FaAppStoreIos } from "react-icons/fa";
import { AiFillAndroid, AiFillMobile } from "react-icons/ai";
import { IconType } from "react-icons";
import { theme } from "aagent-ui";
import Steps, { ToggleStep as ToggleStepRaw } from "./Steps";
import DefaultHeaderFooter from "./DefaultHeaderFooter";
import PickContract from "./assets/web_influencer/pick_contract.png";
import EnjoyLife from "./assets/web_influencer/enjoy_life.png";
import CollectReceipt from "./assets/web_influencer/collect_receipt.png";
import ShareLife from "./assets/web_influencer/share_life.png";
import GetPaid from "./assets/web_influencer/get_paid.png";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Headline from "./Headline";
import { ANDROID_APP_URL, IOS_APP_URL } from "./constants";
import FAQSection from "./FAQSection";

function ToggleStep({ src, title, ...rest }: any) {
  return (
    <ToggleStepRaw title={title} {...rest}>
      <Box sx={{ display: "flex", justifyContent: "center", p: "16px 0" }}>
        <Box
          component="img"
          src={src}
          alt={title}
          sx={{ flex: "0 1 auto", minWidth: 0 }}
        />
      </Box>
    </ToggleStepRaw>
  );
}

function LogoToggleStep(props: any) {
  return (
    <ToggleStep
      dotSx={{
        backgroundColor: "#fff",
        p: 0,
        m: "6px -10px",
        border: "none",
      }}
      icon={
        <img
          alt="logo"
          src="/logo192.png"
          height="32px"
          style={{ borderRadius: "50%" }}
        />
      }
      {...props}
    />
  );
}

function DownloadSection({
  Icon,
  text,
  href,
}: {
  Icon: IconType;
  text: string;
  href?: string;
}) {
  const color = !!href ? theme.palette.primary.main : theme.palette.grey["300"];
  const hoverColor = !!href
    ? theme.palette.primary.dark
    : theme.palette.grey["300"];
  return (
    <Box
      component="a"
      href={href}
      sx={{
        textAlign: "center",
        color,
        ":hover": { color: hoverColor },
        my: "10px",
      }}
      target="_blank"
    >
      <Box component={Icon} size={60} />
      <Typography>{text}</Typography>
    </Box>
  );
}

function DownloadApp() {
  return (
    <Container sx={{ my: "60px" }}>
      <Box
        sx={{
          p: "40px 12px",
          mx: { xs: "20px", md: "44px" },
          border: "1px dotted",
          borderRadius: "16px",
          borderColor: "primary.light",
        }}
      >
        <Box sx={{ textAlign: "center", mb: "42px" }}>
          <Typography variant="h1">Join Beta</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              justifyContent: "space-around",
            },
          }}
        >
          <DownloadSection
            Icon={FaAppStoreIos}
            text="iOS app"
            href={IOS_APP_URL}
          />
          <DownloadSection
            Icon={AiFillAndroid}
            text="Android"
            href={ANDROID_APP_URL}
          />
          <DownloadSection
            Icon={AiFillMobile}
            text="Web"
            href="https://influencer.aagent.co"
          />
        </Box>
      </Box>
    </Container>
  );
}

function InfluencerSteps() {
  const [expanded, setExpanded] = useState<any>({
    pickContract: false,
    enjoyLife: false,
    collectReceipt: false,
    shareLife: false,
    getPaid: false,
  });

  const expandAll = () =>
    setExpanded({
      pickContract: true,
      enjoyLife: true,
      collectReceipt: true,
      shareLife: true,
      getPaid: true,
    });
  const collapseAll = () =>
    setExpanded({
      pickContract: false,
      enjoyLife: false,
      collectReceipt: false,
      shareLife: false,
      getPaid: false,
    });
  const allCollapsed = Object.keys(expanded).every((key) => !expanded[key]);

  return (
    <Container sx={{ mt: { xs: "30px", md: "80px" } }}>
      <Box sx={{ textAlign: "center", mb: "30px" }}>
        <Typography variant="h1">How it works</Typography>
      </Box>

      {/* Expand all Icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mr: "40px",
          cursor: "pointer",
        }}
        onClick={allCollapsed ? expandAll : collapseAll}
      >
        <OpenInFullIcon
          sx={{ color: "primary.main", fontWeight: "bold", mr: "10px" }}
        />
        <Typography
          sx={{ fontSize: "16px", color: "primary.main", fontWeight: "bold" }}
        >
          {allCollapsed ? "Expand All" : "Collapse All"}
        </Typography>
      </Box>

      {/* Steps for influencers to follow */}
      <Steps>
        <LogoToggleStep
          title="Pick your favorite contract from our App"
          src={PickContract}
          expanded={expanded.pickContract}
          setExpanded={(val: boolean) =>
            setExpanded({ ...expanded, pickContract: val })
          }
        />
        <ToggleStep
          title="Enjoy life like you are already doing"
          src={EnjoyLife}
          expanded={expanded.enjoyLife}
          setExpanded={(val: boolean) =>
            setExpanded({ ...expanded, enjoyLife: val })
          }
        />
        <ToggleStep
          title="Collect receipt"
          src={CollectReceipt}
          expanded={expanded.collectReceipt}
          setExpanded={(val: boolean) =>
            setExpanded({ ...expanded, collectReceipt: val })
          }
        />
        <ToggleStep
          title="Share amazing life on social media"
          src={ShareLife}
          expanded={expanded.shareLife}
          setExpanded={(val: boolean) =>
            setExpanded({ ...expanded, shareLife: val })
          }
        />
        <LogoToggleStep
          title="Upload screenshots and get $$$"
          src={GetPaid}
          expanded={expanded.getPaid}
          setExpanded={(val: boolean) =>
            setExpanded({ ...expanded, getPaid: val })
          }
        />
      </Steps>
    </Container>
  );
}

export default function InfluencersV2() {
  return (
    <DefaultHeaderFooter>
      <Headline
        title="Influencers"
        subtitle="Earn an average of $50-$80 starting with 20 likes on your post"
      />
      <DownloadApp />
      <InfluencerSteps />
      <FAQSection />
    </DefaultHeaderFooter>
  );
}
