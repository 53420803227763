import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DefaultHeaderFooter from "./DefaultHeaderFooter";
import { Link } from "@mui/material";
import { Link as RouteLink } from "react-router-dom";

export default function Careers() {
  return (
    <DefaultHeaderFooter>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              variant="h1"
              align="center"
              color="text.primary"
              gutterBottom
            >
              We are hiring!
            </Typography>
            <Typography align="center" color="text.primary" paragraph>
              Join us and revolutionize the marketing industry! Below are the
              roles we are trying to fill. Please email your resume to{" "}
              <Link href="mailto:careers@aagent.co">careers@aagent.co</Link> and
              indicate the role(s) you are interested in. Follow{" "}
              <Link
                href="https://www.linkedin.com/company/anonymous-agent/"
                target="_blank"
              >
                our LinkedIn
              </Link>{" "}
              account for more careers and product updates.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={2}>
            <Grid item key={1} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography gutterBottom variant="h2">
                    <Link
                      component={RouteLink}
                      to="/careers/business-development-sales-associate"
                    >
                      Business Development / Sales Associate
                    </Link>
                  </Typography>
                  <Typography>
                    In person research to understand market needs and close
                    deals to help customers.
                  </Typography>

                  <Link
                    sx={{ marginTop: "20px" }}
                    component={RouteLink}
                    to="/careers/market-research-sales-associate"
                  >
                    <Typography>Learn More ...</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={2} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography gutterBottom variant="h2">
                    <Link
                      component={RouteLink}
                      to="/careers/product-research-analyst"
                    >
                      Product Research Analyst
                    </Link>
                  </Typography>
                  <Typography>
                    Research into various social media platforms and design the
                    best product for our users.
                  </Typography>

                  <Link
                    sx={{ marginTop: "20px" }}
                    component={RouteLink}
                    to="/careers/product-research-analyst"
                  >
                    <Typography>Learn More ...</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={3} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography gutterBottom variant="h2">
                    <Link
                      component={RouteLink}
                      to="/careers/campus-ambassador-marketing-associate"
                    >
                      Campus Ambassador / Marketing Associate
                    </Link>
                  </Typography>
                  <Typography>
                    Reach out to potential influencers and keep the ball moving!
                  </Typography>

                  <Link
                    sx={{ marginTop: "20px" }}
                    component={RouteLink}
                    to="/careers/campus-ambassador-marketing-associate"
                  >
                    <Typography>Learn More ...</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={4} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography gutterBottom variant="h2">
                    <Link component={RouteLink} to="/careers/ui-designer">
                      UI Designer
                    </Link>
                  </Typography>
                  <Typography>
                    Create stunning UI design for app and web.
                  </Typography>

                  <Link
                    sx={{ marginTop: "20px" }}
                    component={RouteLink}
                    to="/careers/ui-designer"
                  >
                    <Typography>Learn More ...</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={5} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography gutterBottom variant="h2">
                    <Link component={RouteLink} to="/careers/app-tester">
                      App Tester & Promoter
                    </Link>
                  </Typography>
                  <Typography>
                    Test our app as an influencer and optionally promote it.
                  </Typography>

                  <Link
                    sx={{ marginTop: "20px" }}
                    component={RouteLink}
                    to="/careers/app-tester"
                  >
                    <Typography>Learn More ...</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </DefaultHeaderFooter>
  );
}
