import DefaultHeaderFooter from "./DefaultHeaderFooter";
import { Alert, Box, Container, TextField, Typography } from "@mui/material";
import { Button } from "aagent-ui";
import SendIcon from "@mui/icons-material/Send";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { ChangeEvent, SyntheticEvent, useRef, useState } from "react";
import secrets from "./secrets.json";

export default function ContactUs() {
  const [formState, setFormState] = useState({
    sentSuccess: false,
    errorMessage: "",

    // Form inputs
    fromName: "",
    content: "",
    fromEmail: "",
  });

  const form = useRef();

  const sendEmail = (e: SyntheticEvent) => {
    e.preventDefault();

    // TODO: use params from the form
    const { fromName, content, fromEmail } = formState;
    const params = { fromName, content, fromEmail };

    emailjs
      .send(
        String(secrets["EMAIL_JS_SERVICE_ID"]),
        String(secrets["EMAIL_JS_TEMPLATE_ID"]),
        params
      )
      .then(
        () => {
          setFormState({ ...formState, sentSuccess: true, errorMessage: "" });
        },
        (error: EmailJSResponseStatus) => {
          console.log(error);
          setFormState({
            ...formState,
            errorMessage: error.text,
            sentSuccess: false,
          });
        }
      );
  };

  const updateFromEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, fromEmail: e.target.value });
  const updateFromName = (e: ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, fromName: e.target.value });
  const updateContent = (e: ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, content: e.target.value });

  return (
    <DefaultHeaderFooter>
      <Container
        sx={{ padding: "40px 20px", display: "flex", justifyContent: "center" }}
      >
        <Box>
          <Typography>
            Please enter your information below, and we will follow up shortly.
          </Typography>
          <Box
            component="form"
            onSubmit={sendEmail}
            ref={form}
            noValidate
            sx={{ display: "flex", flexDirection: "column", maxWidth: "600px" }}
          >
            <Box sx={{ margin: "10px" }}></Box>

            {/* Name */}
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              size="small"
              margin="normal"
              value={formState.fromName}
              onChange={updateFromName}
            />

            {/* Email */}
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              size="small"
              margin="normal"
              value={formState.fromEmail}
              onChange={updateFromEmail}
            />

            {/* Email Content */}
            <TextField
              id="content"
              label="Message"
              variant="outlined"
              multiline
              minRows={5}
              margin="normal"
              value={formState.content}
              onChange={updateContent}
            />

            {/* Submit */}
            <Box sx={{ margin: "10px" }}></Box>
            <Box>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Send
              </Button>
            </Box>

            <Box sx={{ margin: "10px" }}></Box>
            {formState.sentSuccess && (
              <Alert severity="success">Email sent successfully</Alert>
            )}
            {formState.errorMessage && (
              <Alert severity="error">
                Fail to send email: {formState.errorMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </Container>
    </DefaultHeaderFooter>
  );
}
