import { Routes, Route, Navigate } from "react-router-dom";

import HomeV2 from "./HomeV2";
import MerchantsV2 from "./MerchantsV2";
import ContactUs from "./ContactUs";
import Careers from "./Careers";
import NameCheck from "./CheckName";
import Pricing from "./pricing/Pricing";
import BusinessDevelopmentSalesAsso from "./careers/jobs/BusinessDevelopmentSalesAsso";
import ProductResearchAsso from "./careers/jobs/ProductResearchAsso";
import CampusAmbassadorMarketingAsso from "./careers/jobs/CampusAmbassadorMarketingAsso";
import UIDesigner from "./careers/jobs/UIDesigner";
import AppTester from "./careers/jobs/AppTester";
import InfluencersV2 from "./InfluencersV2";
import { Terms, Privacy, PaymentTerms } from "./ContentPages";
import Beta from "./Beta";

// https://stackoverflow.com/a/44563899
function ExternalRedirect({ url }: { url: string }) {
  window.location.replace(url);
  return null;
}

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomeV2 />} />
      <Route path="/merchants" element={<MerchantsV2 />} />
      <Route path="/influencers" element={<InfluencersV2 />} />
      <Route path="/contact" element={<ContactUs />} />

      <Route path="/careers" element={<Careers />} />
      <Route
        path="/careers/business-development-sales-associate"
        element={<BusinessDevelopmentSalesAsso />}
      />
      <Route
        path="/careers/campus-ambassador-marketing-associate"
        element={<CampusAmbassadorMarketingAsso />}
      />
      <Route
        path="/careers/product-research-analyst"
        element={<ProductResearchAsso />}
      />
      <Route path="/careers/ui-designer" element={<UIDesigner />} />
      <Route path="/careers/app-tester" element={<AppTester />} />

      <Route path="/pricing" element={<Pricing />} />
      <Route path="/namecheck" element={<NameCheck />} />

      <Route path="/terms" element={<Terms />} />
      <Route path="/terms/admin-edit" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/privacy/admin-edit" element={<Privacy />} />
      <Route path="/payment-terms" element={<PaymentTerms />} />
      <Route path="/payment-terms/admin-edit" element={<PaymentTerms />} />

      <Route path="/beta" element={<Beta />} />

      <Route path="/qr" element={<Navigate replace to="/merchants" />} />
      <Route path="/qr-instagram" element={<Beta />} />
      <Route path="/qr-store" element={<Beta />} />
      <Route path="/qr-street" element={<Beta />} />
      <Route path="/qr-other" element={<Beta />} />
      <Route path="/qr-influencer-dm" element={<Beta />} />
      <Route path="/qr-group-chat" element={<Beta />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
