import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouteLink } from "react-router-dom";
import { Link } from "@mui/material";
import { useEffect, useState } from "react";

const pages = [
  {
    label: "Merchants",
    link: "/merchants",
  },
  {
    label: "Influencers",
    link: "/influencers",
  },
  {
    label: "Pricing",
    link: "/pricing",
  },
  {
    label: "Careers",
    link: "/careers",
  },
  {
    label: "Contact",
    link: "/contact",
  },
];
export default function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [atTop, setAtTop] = useState<boolean>(true);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    let isMounted = true;
    window.addEventListener("scroll", () => {
      const newAtTop = window.scrollY < 10;
      if (isMounted && newAtTop !== atTop) {
        setAtTop(newAtTop);
      }
    });
    return () => {
      isMounted = false;
    };
  });

  return (
    <AppBar
      position="fixed"
      sx={{ background: atTop ? "none" : "white", width: "100vw" }}
      elevation={atTop ? 0 : 1}
    >
      <Box sx={{ px: "20px" }}>
        <Toolbar disableGutters>
          <Link component={RouteLink} to="/" color="inherit" underline="none">
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <img
                src="/logo192.png"
                style={{ width: "40px", marginRight: "14px" }}
                alt="Logo"
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  width: "100px",
                  color: "secondary.main",
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
              >
                Anonymous Agent
              </Typography>
            </Box>
          </Link>

          <Box
            sx={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(({ label, link }) => (
                <Link
                  key={label}
                  component={RouteLink}
                  to={link}
                  underline="hover"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      sx={{ color: "text.primary" }}
                    >
                      {label}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map(({ label, link }) => (
              <Link
                key={label}
                component={RouteLink}
                to={link}
                underline="hover"
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "text.primary",
                    display: "block",
                    textTransform: "none",
                  }}
                >
                  {label}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
