// https://create-react-app.dev/docs/adding-custom-environment-variables/
// NODE_ENV can only be:
//   - development: when you run "npm start"
//   - production: when you run "npm run build"
export const API_DOMAIN =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://api.aagent.co";

export const COLOR_GRADIENT =
  "linear-gradient(227.23deg, #BCC2FC 16.89%, #E9F8F9 54.53%, #E9F8F9 55.67%, #F5F3F8 99.31%)";

export const IOS_APP_URL =
  "https://apps.apple.com/us/app/anonymous-agent/id1631247390";

export const ANDROID_APP_URL =
  "https://play.google.com/store/apps/details?id=com.aagent.aagent_webview";

export const INFLUENCERS_URL = "https://aagent.co/influencers";
