// type MobileDeviceType = "Windows Phone" | "Android" | "iOS" | "unknown";
export enum OperatingSystem {
  WINDOWS_PHONE,
  ANDROID,
  IOS,
  UNKNOWN,
}

// https://stackoverflow.com/a/39749991/4115411
export function getMobileOperatingSystem(): OperatingSystem {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OperatingSystem.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return OperatingSystem.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OperatingSystem.IOS;
  }

  return OperatingSystem.UNKNOWN;
}
