import DefaultHeaderFooter from "../../DefaultHeaderFooter";
import { Box, Container, Link, Typography } from "@mui/material";

export default function BusinessDevelopmentSalesAsso() {
  return (
    <DefaultHeaderFooter>
      <Container
        sx={{
          padding: "50px 40px 30px 40px",
          margin: "auto",
          maxWidth: { md: "840px" },
        }}
      >
        <Typography variant="h1" sx={{ my: "40px", textAlign: "center" }}>
          Business Development & Sales Associate (NYC)
        </Typography>

        <Typography>
          We are a tech startup focusing on effective social media marketing. We
          are looking for part-time Business Development & Sales associates.
          Potential full-time and permanent opportunities available. This is not
          a remote role. Most of the internal meetings will be online, but you
          do need to talk to clients face to face. The compensation structure
          can be either base + low commission or high commission only. On
          average $35-$70/hour + overall performance bonus. Detailed training
          will be provided. The final interview will be in person.
        </Typography>

        <Box sx={{ margin: "30px" }}></Box>

        {/* Responsibilities */}
        <Typography variant="h6">Responsibilities</Typography>

        <ul>
          <li>
            <Typography>
              Analyze different regions and customer features for sales
              opportunities
            </Typography>
          </li>

          <li>
            <Typography>
              Conduct market research to identify selling possibilities and
              evaluate customer needs
            </Typography>
          </li>

          <li>
            <Typography>
              Gather feedback from customers or prospects and share with
              internal teams
            </Typography>
          </li>
          <li>
            <Typography>
              Ability to work independently and also in a cross functional team
            </Typography>
          </li>
          <li>
            <Typography>Negotiate/close deals</Typography>
          </li>
        </ul>

        {/* Requirements */}
        <Typography variant="h6">Requirements</Typography>

        <ul>
          <li>
            <Typography>
              No experience required, but you need to be approachable,
              comfortable talking to strangers and enjoy conversations
            </Typography>
          </li>

          <li>
            <Typography>
              Proficiency in English. Please indicate if you are fluent in other
              languages
            </Typography>
          </li>

          <li>
            <Typography>
              Sharp insights into people's wording and body language is a plus
            </Typography>
          </li>
          <li>
            <Typography>
              Fast learner and passion for sales or startups
            </Typography>
          </li>
          <li>
            <Typography>
              Self-motivated with a results-driven approach
            </Typography>
          </li>
        </ul>

        {/* Perks */}
        <Typography variant="h6">Perks</Typography>

        <ul>
          <li>
            <Typography>Flexibile schedule</Typography>
          </li>

          <li>
            <Typography>Potential full-time extension</Typography>
          </li>

          <li>
            <Typography>Potential permanent position</Typography>
          </li>

          <li>
            <Typography>Potential leadership opportunities</Typography>
          </li>
          <li>
            <Typography>
              Competitive compensation (on average $35-$70/hour + overall
              performance bonus)
            </Typography>
          </li>
        </ul>

        <Box sx={{ margin: "40px" }}></Box>

        <Typography>
          <b>
            To Apply: please email your resume to{" "}
            <Link href="mailto:careers@aagent.co">careers@aagent.co</Link> and
            indicate the role(s) that you are interested in.
          </b>
        </Typography>
      </Container>
    </DefaultHeaderFooter>
  );
}
