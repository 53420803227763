import DefaultHeaderFooter from "../../DefaultHeaderFooter";
import { Box, Link, Container, Typography } from "@mui/material";

export default function CampusAmbassadorMarketingIntern() {
  return (
    <DefaultHeaderFooter>
      <Container
        sx={{
          padding: "50px 40px 30px 40px",
          margin: "auto",
          maxWidth: { md: "840px" },
        }}
      >
        <Typography variant="h1" sx={{ my: "40px", textAlign: "center" }}>
          Campus Ambassador & Marketing Associate (NYC)
        </Typography>

        <Typography>
          We are a tech startup focusing on effective social media marketing. We
          are looking for part-time Campus Ambassador and Marketing associates.
          Potential full time opportunities available. This may not be a remote
          role depending which part you play. There is no base salary. However,
          you will be compensated with commissions depending on your
          performance.
        </Typography>

        <Box sx={{ margin: "30px" }}></Box>

        {/* Responsibilities */}
        <Typography variant="h6">Responsibilities</Typography>

        <ul>
          <li>
            <Typography>
              Plan and run marketing campaigns on campus virtually and in person
            </Typography>
          </li>

          <li>
            <Typography>
              Gather and analyze feedback from users in a systematic manner
            </Typography>
          </li>

          <li>
            <Typography>
              Ability to work independently and also in a cross functional team
            </Typography>
          </li>
          <li>
            <Typography>
              Be an advocate of the beta product at launch
            </Typography>
          </li>
          <li>
            <Typography>
              Optional: design and manage marketing campaigns off campus
            </Typography>
          </li>
        </ul>

        {/* Requirements */}
        <Typography variant="h6">Requirements</Typography>

        <ul>
          <li>
            <Typography>
              No experience required, but you need to be approachable,
              comfortable talking to strangers and enjoy conversations
            </Typography>
          </li>

          <li>
            <Typography>
              Well connected, resourceful and highly involved in various
              organizations and communities
            </Typography>
          </li>

          <li>
            <Typography>
              Proficiency in English. Please indicate if you are fluent in other
              languages
            </Typography>
          </li>
          <li>
            <Typography>
              Creative problem solver. Self-motivated with a results-driven
              approach
            </Typography>
          </li>
          <li>
            <Typography>
              Fast learner and passion for marketing or startups
            </Typography>
          </li>
        </ul>

        {/* Perks */}
        <Typography variant="h6">Perks</Typography>

        <ul>
          <li>
            <Typography>Flexibile schedule</Typography>
          </li>

          <li>
            <Typography>Potential full-time extension</Typography>
          </li>

          <li>
            <Typography>Potential permanent position</Typography>
          </li>

          <li>
            <Typography>Potential leadership opportunities</Typography>
          </li>

          <li>
            <Typography>Competitive commission</Typography>
          </li>
        </ul>

        <Box sx={{ margin: "40px" }}></Box>

        <Typography>
          <b>
            To Apply: please email your resume to{" "}
            <Link href="mailto:careers@aagent.co">careers@aagent.co</Link> and
            indicate the role(s) that you are interested in.
          </b>
        </Typography>
      </Container>
    </DefaultHeaderFooter>
  );
}
