import { Box, Container, Typography } from "@mui/material";
import { COLOR_GRADIENT } from "./constants";
import Wave from "./assets/wave.png";
import InfluencerTopVisual from "./assets/web_influencer/influencer_top_visual.png";

export default function Headline({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) {
  return (
    <Box
      sx={{
        background: COLOR_GRADIENT,
        height: { xs: "354px", md: "400px" },
        position: "relative",
      }}
    >
      <Container sx={{ pt: "100px", position: "relative", overflow: "hidden" }}>
        <Box
          sx={{
            // backgroundImage: `url("${InfluencerTopVisual}")`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "202px",
            position: "relative",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "secondary.main",
                fontSize: { xs: "44px", md: "60px" },
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
            {!!subtitle && (
              <Typography sx={{ mt: "14px", fontWeight: 300, px: "20px" }}>
                {subtitle}
              </Typography>
            )}
          </Box>
          <Box
            component="img"
            src={InfluencerTopVisual}
            alt="Visual"
            sx={{
              position: "absolute",
              margin: "auto",
              // width: "100%",
              // maxWidth: "982px",
              height: "100%",
              aspectRatio: "982 / 202",
            }}
          />
        </Box>

        <Box
          component="img"
          src={Wave}
          alt="Visual"
          sx={{
            height: { xs: "48px", md: "80px", lg: "112px" },
            width: "100%",
            position: "absolute",
            top: { xs: "240px", md: "200px", lg: "174px" },
          }}
        />
      </Container>
    </Box>
  );
}
