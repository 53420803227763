import { useFormik } from "formik";
import { Box, SxProps, Alert } from "@mui/material";
import { LoadingButton, TextField, toast } from "aagent-ui";
import { useLocation } from "react-router-dom";
import { API_DOMAIN } from "./constants";
import { useState } from "react";
import * as yup from "yup";
import secrets from "./secrets.json";
import emailjs from "@emailjs/browser";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function EmailWaitList({
  sx,
  text,
  successMessage,
}: {
  sx?: SxProps;
  text?: string;
  successMessage?: string;
}) {
  text = text || "Join Wait List";
  successMessage = successMessage || "You are on on the wait list now!";
  const location = useLocation();
  const initialValues = { email: "" };
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (values: typeof initialValues) => {
    setLoading(true);
    const valuesWithPath = {
      ...validationSchema.cast(values),
      path: location.pathname,
    };

    fetch(`${API_DOMAIN}/waitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(valuesWithPath),
    })
      .then(async (response) => {
        if (response.status === 200) {
          emailjs.send(
            String(secrets["EMAIL_JS_SERVICE_ID"]),
            String(secrets["EMAIL_JS_WAITLIST_TEMPLATE_ID"]),
            valuesWithPath
          );
          setAlertOpen(true);
          toast.success("You are on the wait list");
        } else {
          const json = await response.json();
          console.log(json["message"]);
          toast.error("Unexpected error");
        }
      })
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box sx={sx} component={"form"} onSubmit={formik.handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: "10px",
          rowGap: "15px",
        }}
      >
        <Box sx={{ flex: 1, minWidth: "240px" }}>
          <TextField
            name="email"
            fullWidth
            placeholder="Email Address"
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
        <LoadingButton
          sx={{
            borderRadius: "28px",
            p: "14px 20px",
            lineHeight: 1,
            flex: 1,
            minWidth: "137.91px",
          }}
          fullWidth
          variant="contained"
          type="submit"
          loading={loading}
          disabled={!!alertOpen}
        >
          {text}
        </LoadingButton>
      </Box>

      {alertOpen && (
        <Box sx={{ padding: "10px 20px" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, maxWidth: "280px" }}
          >
            {successMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
}
