import { Box, Container, Link, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function IconLink({ icon, href }: { icon: any; href: string }) {
  return (
    <Link
      href={href}
      target="_blank"
      color="text.secondary"
      sx={{ ":hover": { color: "primary.main" } }}
    >
      <FontAwesomeIcon style={{ fontSize: "20px" }} icon={icon} />
    </Link>
  );
}

export default function Footer() {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          my: "30px",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {/* Left */}
        <Box sx={{ display: "flex", alignItems: "center", mr: "20px" }}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"© "}
            <Link color="inherit" href="/">
              Anonymous Agent Inc.
            </Link>{" "}
            {new Date().getFullYear()}
          </Typography>
          <Typography sx={{ px: { xs: "4px", md: "8px" } }}>·</Typography>
          <Link href="/terms" sx={{ color: "text.secondary" }}>
            Terms
          </Link>
          <Typography sx={{ px: { xs: "4px", md: "8px" } }}>·</Typography>
          <Link href="/privacy" sx={{ color: "text.secondary" }}>
            Privacy
          </Link>
        </Box>

        {/* Right */}
        <Box sx={{ display: "flex", my: "8px" }}>
          <IconLink
            icon={faInstagram}
            href="https://www.instagram.com/aagent.co/"
          />
          <Box sx={{ m: { xs: "4px", md: "8px" } }} />
          <IconLink
            icon={faLinkedin}
            href="https://www.linkedin.com/company/anonymous-agent"
          />
          <Box sx={{ m: { xs: "4px", md: "8px" } }} />
          <IconLink icon={faTiktok} href="https://www.tiktok.com/@aagent.co" />
        </Box>
      </Box>
    </Container>
  );
}
